<template>
    <div>
        <van-image
            width="100%"
            :src="article.thumb"
            @load="() => { this.imgLoaded = true }"
            :style="{ height: imgLoaded ? null : '180px' }"
        />

        <van-cell title="" v-if="loading">
            <template #label>
                <van-skeleton title :row="5" style="padding: 0px" />
            </template>
        </van-cell>

        <template v-else>
            <t-content>
                <template #title>{{ article.title }}</template>
                <template #meta>
                    {{ article.category.name }}
                    <span class="divider">/</span>
                    {{ article.author }}
                    <span class="divider">/</span>
                    {{ article.created_at }}
                </template>
                <template #content>
                    <div class="article-content" v-html="article.content"></div>
                </template>
                <template #footer>
                    <van-row type="flex" justify="space-between">
                        <van-col span="12">阅读数 {{ article.views }}</van-col>
                        <van-col span="12"><collect v-if="!loading" :medium="article" /></van-col>
                    </van-row>
                </template>
            </t-content>
        </template>
    </div>
</template>

<script>
import { getArticle } from '@/api/article'
import Collect from '@/components/Collect'
import wechatMixin from '@/utils/mixin'
import TContent from '@/components/TContent'

export default {
    name: 'ReaderShow',
    mixins: [wechatMixin],

    data() {
        return {
            loading: true,
            imgLoaded: false,
            article: {
                title: '',
                author: '',
                times: 0,
                views: 0
            }
        }
    },
    metaInfo () {
        return {
            title: this.article.title
        }
    },
    components: { Collect, TContent },
    methods: {},
    created() {
        const articleId = this.$route.params.id
        getArticle(articleId).then(res => {
            this.article = res.data
            this.loading = false
            this.registerWechatShare(this.article)
        })
    }
};
</script>

<style lang="less">
    .divider {
        margin: 0px 2px;
    }
</style>
